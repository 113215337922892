// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import {RemoteHelper} from "../src/remote_helper";

import('../src/core_extentions');

import Rails from "@rails/ujs"

import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "jquery-ui"
import 'bootstrap/js/dist/alert'
import 'bootstrap/js/dist/button'
import 'bootstrap/js/dist/carousel'
import 'bootstrap/js/dist/collapse'
import 'bootstrap/js/dist/dropdown'
import 'bootstrap/js/dist/index'
import 'bootstrap/js/dist/modal'
import 'bootstrap/js/dist/popover'
import 'bootstrap/js/dist/scrollspy'
import 'bootstrap/js/dist/tab'
import 'bootstrap/js/dist/toast'
import 'bootstrap/js/dist/tooltip'
import 'bootstrap/js/dist/util'
import 'jquery-mask-plugin'
import {Lnxtable} from "../src/lnx_table";

global.Rails = Rails;
Rails.start()
// Turbolinks.start()
ActiveStorage.start()

import $ from 'jquery';
global.$ = jQuery;
// Add DataTables jQuery plugin

import {gotoUrl} from "../src/common_methods";


var maskList = {
    ipv4: [
        '099.099.099.099',
        { translation:
                { 'Z': { pattern: /[0-9]/} }
        }
    ],
    url: [
        'https://Z', {
            placeholder: 'https://www.example.com',
            translation: {
                'Z': {
                    pattern: /[^\s]/,
                    recursive: true
                }
            }
        }
    ],
    sealNumber: [
        '0000000000', {
            placeholder: '__________'
        }
    ]
};

document.addEventListener('DOMContentLoaded', () => {

    $('[data-remote]').each(function (_index, el) {
        new RemoteHelper($(el))
    })

    $('[data-mask]').each(function (_index, el) {
        $(el).mask(...maskList[el.dataset.mask])
    })

    $('table.datatable').each(function (_index, el) {
        new Lnxtable($(el))
    })

    $("#sign-in-user").on('ajax:success', function () {
        gotoUrl(location.origin);
    })

    $("#sign-up-user").on('ajax:success', function () {
        gotoUrl(location.origin);
    })

})