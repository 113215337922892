import locale from '../src/renderer_locales.json';
import {htmlLang} from './common_methods';
import {showFlashMessage, closeAlerts} from "./flash";


class ErrorsRenderer {
	constructor(form) {
		this.form = form;
		this.modelName = this.getModelName(form);
		this.errors = {};
		this.messages = locale[htmlLang];
		this.formErrorMessage = this.messages['record_invalid'];
		this.buildElementsTree();
		this.orderElementsTree();
	}

	getModelName(form) {
		return form.data('modelname');
	}

	clearErrors(customFields) {
		if (customFields == null) {
			customFields = false;
		}
		this.errors = {};
		if (!customFields) {
			closeAlerts();
		}
		$('.invalid-feedback', this.form).remove();
		$('input, select, text-area, .custom-error, .base-error, .select2-selection', this.form).removeClass('is-invalid');
	}

	buildElementsTree() {
		this.tree = {};
		return $('input, select, textarea, .custom-error, .base-error', this.form).each((_, el) => {
			el = $(el);
			const name = el.attr('name') || el.data('name');
			if (!name) {
				return true;
			}
			let cur = this.tree;
			const nameParts = this.parseName(name);
			return (() => {
				const result = [];
				for (let i = 0; i < nameParts.length; i++) {
//        workaround for array attributes
					const part = nameParts[i];
					if (String(Number(part)) === part) {
						continue;
					}
					const isLast = i === (nameParts.length - 1);
					if (!cur[part]) {
						cur[part] = {};
					}
					if (isLast) {
						cur[part]['__element'] = el;
					}
					result.push(cur = cur[part]);
				}
				return result;
			})();
		});
	}

	orderElementsTree(root) {
		if (root == null) {
			root = this.tree;
		}
		let keyCounter = 0;
		return (() => {
			const result = [];
			for (let k of Object.keys(root || {})) {
				const v = root[k];
				if (/^\d+$/.test(k)) {
					delete root[k];
					root[keyCounter++] = v;
				}
				if ($.isPlainObject(v)) {
					result.push(this.orderElementsTree(v));
				} else {
					result.push(undefined);
				}
			}
			return result;
		})();
	}

	parseName(name) {
		const res = [];
		let parts = name.split(/[\[\]\.]+/);
		if (!parts[parts.length - 1]) {
			parts = parts.slice(0, -1);
		}
		return Array.from(parts).map((part) => part.replace('_attributes', ''));
	}

	findElement(parts) {
		let cur = this.tree;
		for (let part of Array.from(parts)) {
			if (cur.hasOwnProperty(part)) {
				cur = cur[part];
			} else {
				return false;
			}
		}
		return cur['__element'];
	}

	renderElementErrors(el, errors, suggestion) {
		var component, confirm_message, errorsBlock, inputGroup, suggestionBlock;
		inputGroup = el.closest('.form-group, .form-check, .input-group');
		errorsBlock = $("<div class='invalid-feedback'>").html(errors.join(", "));
		component = inputGroup.find('input,textarea').data('component');
		confirm_message = inputGroup.closest('fieldset').data('confirm-message');
		if (suggestion) {
			suggestionBlock = $("<span class='suggestion'><button name='button' type='button' class='confirm-option btn btn-primary'>" + confirm_message + "</button></span>");
			suggestionBlock.attr('data-component', component);
			suggestionBlock.attr('data-option', suggestion);
			errorsBlock.append(suggestionBlock);
		}
		inputGroup.append(errorsBlock);
		if (el.hasClass('select2-hidden-accessible')) {
			el.trigger('hasError');
			el.parent().find('.select2-selection').addClass('is-invalid');
		} else {
			el.addClass('is-invalid').trigger('hasError');
		}
	}

	renderBaseErrors(el, errors) {
		const div = $('<div>', {class: 'alert alert-danger persistent spaced base-errors'});
		div.append($('<button>', {class: 'close'}).attr('data-dismiss', 'alert').html(' &times;'));
		const errorsList = $('<ul>').appendTo(div);
		for (let message of Array.from(errors)) {
			$('<li>').html(message).appendTo(errorsList);
		}
		div.appendTo(el);
	}

	renderJsonErrors(response) {
		var $baseAlert, baseErrors, div, el, errorsList, errs, k, message, messages, suggestion, suggestions, _i, _len;
		div = $('<div>').prepend($('<strong>').html(this.formErrorMessage));
		$baseAlert = this.form.find('.errors-base');
		baseErrors = response.base;
		messages = response.full_messages;
		suggestions = response.suggestions || {};
		if ($baseAlert && baseErrors && baseErrors.length > 0) {
			this.renderBaseErrors($baseAlert, baseErrors);
		}
		if (messages && messages.length > 0) {
			errorsList = $('<ul>').appendTo(div);
			for (_i = 0, _len = messages.length; _i < _len; _i++) {
				message = messages[_i];
				$('<li>').html(message).appendTo(errorsList);
			}
			showFlashMessage(div, 'alert');
		}
		for (k in response) {
			// if (!__hasProp.call(response, k)) continue;
			errs = response[k];
			suggestion = suggestions[k];
			el = this.findElement([this.modelName].concat(this.parseName(k)));
			if (el) {
				this.renderInputErrors(el, errs, suggestion);
			}
		}
	}

	renderInputErrors(el, arr, suggestion) {
		if (el.attr('name') || el.hasClass('custom-error')) {
			this.renderElementErrors(el, arr, suggestion);
		} else {
			this.renderBaseErrors(el, arr);
		}
	}

	renderTurbolinksErrors(messages) {
		const div = $('<div>');
		div.prepend($('<h5 class="alert-heading">').html(this.formErrorMessage));
		if (messages.length > 0) {
			const errorsList = $('<ul>').appendTo(div);
			for (let message of Array.from(messages)) {
				$('<li>').html(message).appendTo(errorsList);
			}
		}
		showFlashMessage(div, 'alert');
	}

	render(response, flashOnly) {
		if (flashOnly == null) {
			flashOnly = false;
		}
		this.clearErrors();
		if (flashOnly) {
			return this.renderTurbolinksErrors(response.full_messages);
		} else {
			return this.renderJsonErrors(response);
		}
	}
}

export {ErrorsRenderer};